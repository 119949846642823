<template>
  <main>
    <notification-bar />
    <gramification v-if="gamification" />
    <div class="flex justify-content-between mb-2">
      <h1 class="text-4xl font-semibold">
        Dashboard
      </h1>
      <button
        v-if="profile.is_komship === 1"
        class="bg-white rounded-lg p-[8px] my-auto"
        @click="dashboardSettingShow = true"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/setting-4.svg"
          class="m-auto"
        >
      </button>
    </div>
    <b-row>
      <balance v-if="dashboardSetting.balanceShow" />
      <pending-balance v-if="profile.is_komship === 1 && dashboardSetting.balanceShow" />
      <cashback v-if="profile.is_komship === 1 && dashboardSetting.balanceShow" />
      <order-summary v-if="profile.is_komship === 1 && dashboardSetting.orderSummaryShow" />
      <BCol
        v-if="!isEmpty(hasKomcard)"
        lg="12"
      >
        <BRow>
          <CardFinancial v-if="dashboardSetting.cardFinancial" />
          <LastCardTransaction v-if="dashboardSetting.lastCardTransaction" />
        </BRow>
      </BCol>
      <performence v-if="profile.is_komship === 1 && dashboardSetting.performenceShow" />
      <top-admin v-if="profile.is_komship === 1 && dashboardSetting.topAdminShow" />
      <best-seller v-if="profile.is_komship === 1 && dashboardSetting.bestSellerShow" />
      <customer-loyal v-if="profile.is_komship === 1 && dashboardSetting.customerLoyalShow" />
    </b-row>
    <onboarding v-if="profile.is_kompack === 0 && profile.is_onboarding === false" />
    <onboarding-kompack v-if="profile.is_kompack === 1 && profile.is_onboarding_kompack === false" />
    <b-sidebar
      id="sidebar-dashboard-settings"
      v-model="dashboardSettingShow"
      right
      backdrop
      shadow
    >
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="text-2xl font-semibold mb-0">
              Tampilan Dashboard</p>
            <p class="text-sm mb-0">
              Custom tampilan dashboard sesuai kebutuhanmu</p>
          </div>
          <b-img
            src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
            role="button"
            @click="dashboardSettingShow = false"
          />
        </div>
      </template>
      <div
        v-if="!isEmpty(hasKomcard)"
        class="p-1"
      >
        <b-img
          src="https://storage.googleapis.com/komerce/assets/svg/Komcards-logo.svg"
          class="mb-1"
          width="110"
        />
        <b-row class="text-lg">
          <b-col
            cols="9"
            class="mb-1"
          >
            Arus Keuangan Kartu
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
              v-model="dashboardSetting.cardFinancial"
              class="ml-auto my-auto"
              switch
              @input="updateDashboardSettings()"
            />
          </b-col>
          <b-col
            cols="9"
            class="mb-1"
          >
            Transaksi Terakhir Kartu
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
              v-model="dashboardSetting.lastCardTransaction"
              class="ml-auto my-auto"
              switch
              @input="updateDashboardSettings()"
            />
          </b-col>
        </b-row>
      </div>
      <div class="p-1">
        <b-img
          src="https://storage.googleapis.com/komerce/assets/svg/komship-logo-yellow.svg"
          class="mb-1"
        />
        <b-row class="text-lg">
          <b-col
            cols="9"
            class="mb-1"
          >
            Monitoring Saldo
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
              v-model="dashboardSetting.balanceShow"
              class="ml-auto my-auto"
              switch
              @input="updateDashboardSettings()"
            />
          </b-col>
          <b-col
            cols="9"
            class="mb-1"
          >
            Monitoring Pengiriman
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
              v-model="dashboardSetting.orderSummaryShow"
              class="ml-auto my-auto"
              switch
              @input="updateDashboardSettings()"
            />
          </b-col>
          <b-col
            cols="9"
            class="mb-1"
          >
            Performa Omset & Order
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
              v-model="dashboardSetting.performenceShow"
              class="ml-auto my-auto"
              switch
              @input="updateDashboardSettings()"
            />
          </b-col>
          <b-col
            cols="9"
            class="mb-1"
          >
            Top Admin Order
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
              v-model="dashboardSetting.topAdminShow"
              class="ml-auto my-auto"
              switch
              @input="updateDashboardSettings()"
            />
          </b-col>
          <b-col
            cols="9"
            class="mb-1"
          >
            Produk Terlaris
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
              v-model="dashboardSetting.bestSellerShow"
              class="ml-auto my-auto"
              switch
              @input="updateDashboardSettings()"
            />
          </b-col>
          <b-col
            cols="9"
            class="mb-1"
          >
            Customer Loyal
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
              v-model="dashboardSetting.customerLoyalShow"
              class="ml-auto my-auto"
              switch
              @input="updateDashboardSettings()"
            />
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
  </main>
</template>
<script>
import isEmpty from 'lodash/isEmpty'
import { mapState } from 'vuex'

export default {
  components: {
    Balance: () => import('./Balance.vue'),
    PendingBalance: () => import('./PendingBalance.vue'),
    Cashback: () => import('./Cashback.vue'),
    OrderSummary: () => import('./OrderSummary.vue'),
    Performence: () => import('./Performence.vue'),
    TopAdmin: () => import('./TopAdmin.vue'),
    BestSeller: () => import('./BestSeller.vue'),
    CustomerLoyal: () => import('./CustomerLoyal.vue'),
    NotificationBar: () => import('./NotificationBar.vue'),
    Onboarding: () => import('./Onboarding.vue'),
    OnboardingKompack: () => import('../../kompack/onboarding/Onboarding.vue'),
    Gramification: () => import('./Gramification.vue'),
    CardFinancial: () => import('./CardFinancial.vue'),
    LastCardTransaction: () => import('./LastCardTransaction.vue'),
  },
  data() {
    return {
      isEmpty,
      menus: JSON.parse(localStorage.getItem('ability')),
      dashboardSettingShow: false,
      dashboardSetting: {
        balanceShow: true,
        orderSummaryShow: true,
        performenceShow: true,
        topAdminShow: true,
        bestSellerShow: false,
        customerLoyalShow: false,
        cardFinancial: true,
        lastCardTransaction: true,
      },
    }
  },
  computed: {
    ...mapState('dashboard', ['gamification', 'profile']),
    hasKomcard() {
      return this.menus.filter(menu => menu.subject === 'KartuKomcards')
    },
  },
  mounted() {
    this.getDashboardSettings()
    this.$store.dispatch('dashboard/init')
    this.$store.dispatch('whatsapp/getUpdateBadge')
    this.$store.dispatch('saldo/getBankAccount')
  },
  methods: {
    getDashboardSettings() {
      if (localStorage.getItem('dashboardSetting')) {
        try {
          this.dashboardSetting = JSON.parse(
            localStorage.getItem('dashboardSetting'),
          )
        } catch (e) {
          localStorage.removeItem('dashboardSetting')
        }
      }
    },
    updateDashboardSettings() {
      const parsed = JSON.stringify(this.dashboardSetting)
      localStorage.setItem('dashboardSetting', parsed)
    },
  },
}
</script>
